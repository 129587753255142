import { apiService } from "../../../../common/apiService/service";
import { Constants } from "../../../../common/utils/constants";
import { checkHttpStatus } from "../../../../common/utils/utils";
import UserService from "../../../../services/settings/user.service";
import { transformDrsList, transformDrsPostObject } from "./transformer";
import * as CONSTANTS from '../../../../app/appConstants';


export const fetchDrses = payload => new Promise((resolve, reject) => {
    apiService.post("lm/v1/drs/fetch/drs-list", payload)
        .then(response => {
            if (response.deliveryRunSheets) {
                const responseObject = transformDrsList(response);
                resolve(responseObject);
            }
            else {
                reject("No Delivery Run Sheets Present!")
            }
        })
        .catch(reject);
})

export const createDRS = legacyDRSObject => {
    const postObj = transformDrsPostObject(legacyDRSObject);
    if (!postObj.drsCode) {
        throw "Enter a DRS Code to create DRS!"
    }
    else if (!postObj.drsUserId) {
        throw "Choose user to create DRS!"
    }

    return apiService.post("lm/v1/drs", postObj);
}

export const fetchDRSDetails = drsCode => apiService.get(`lm/v1/drs/${drsCode}`)

export const deleteDRS = drsCode => apiService.delete(`lm/v1/drs/${drsCode}`)

export const fetchUsers = _ => new Promise((resolve, reject) => {
    (new UserService)
        .getUsersByLocation(Constants.userLocationId)
        .then(({ data }) => {
            if (checkHttpStatus(data?.status)) {
                resolve(data?.response?.users)
            }
        })
})

export const fetchUsersNoCache = _ => new Promise((resolve, reject) => {
    (new UserService)
        .getUsersByLocationNoCache(Constants.userLocationId)
        .then(({ data }) => {
            if (checkHttpStatus(data?.status)) {
                resolve(data?.response?.users)
            }
        })
})

export const fetchUnassignedCount = _ => apiService.get(`lm/v1/drs/consignments/count`)

export const fetchUnassignedList = (pageNo, pageSize, showLoader = true) => apiService.get(`lm/v1/drs/consignments?pageNo=${pageNo}&pageSize=${pageSize}`, null, showLoader)

export const linkAWBtoDRS = (drsCode, awb) => apiService.put(`lm/v1/drs/${drsCode}/waybill?waybillNo=${awb}`)

export const delinkAWBfromDRS = (drsCode, awb) => apiService.delete(`lm/v1/drs/${drsCode}/waybill/${awb}`)

export const uploadDrs = postObj => apiService.post("lm/v1/drs/upload", postObj)

export const fetchFeList = () => {
    return apiService.get('fefmlm/riders')
}

export const triggerBatchingApi = (reqParams, showLoader) => {
   return apiService.get("hub/external/bookings", {...reqParams}, showLoader)
}

export const updateBatchingStatus = (data) => {
    return {
        type: CONSTANTS.UPDATE_BATCHING_STATUS,
        payload: data
    }
}